import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Spinner from '../../../../utils/spinner/spinner';
import { fetchReportByProjectId, updateReportPdf } from '../../../report/store/action';

export default function CreateFullPdf({ scanObject }) {
    
    const dispatch = useDispatch();
    const [disabledPage, setDisabledPage] = useState(false);

    const saveScrollPosition = () => {
        const scrollableDiv = document.querySelector(".scrollable-div-table");
        if (scrollableDiv) {
            localStorage.setItem("scrollable-div-table-scrollPosition", scrollableDiv.scrollTop);
        }
    };

    const createPDF = async () => {
        setDisabledPage(true)
        let scansIds = [];
        console.log('createPDF',scanObject);
        
        scanObject.scansList.map(scan => {
            if (scan.statusCode == 200)
                scansIds.push(scan.scanId)
        })
        if (scansIds.length > 0) {
            const reportId = scanObject.reportId;        
            if (reportId == undefined) {
                alert('לא נמצא דוח')
                setDisabledPage(false)
            }
            else {
                const obj = {
                    reportId: reportId,
                    userId: scanObject.userId,
                    scansIdsToPdf: scansIds,
                    locale: 'he',
                    isAddBrokenLetter: true,
                    isFullPdf: true,
                    reportLogId:scanObject._id,
                    isShowImageInNoErrorScan: true
                }                
                console.log('obj😊😊😊', obj);
                const res = await dispatch(updateReportPdf(obj))
                console.log('res', res);
                setDisabledPage(false)
                saveScrollPosition();
                window.open(res, '_blank')
                window.location.reload();
            }
        }
        else {
            alert(' אין הפקת דוח על סריקת שנכשלה')
            setDisabledPage(false)
        }
    }

    return (
        <div>
            <div onClick={createPDF}>
                הפק דוח מלא
            </div>

            {
                disabledPage == true &&
                <Spinner value={100}></Spinner>
            }
        </div>
    )
}