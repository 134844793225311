import { useDispatch, useSelector } from "react-redux";
import { smallScreen, useLanguage } from "../../../utils/constants";
import Header from "../../header/header";
import { getCurrentUserById, showAllUserPopups, updateUserDontShowAgain } from "../store/action";
import { useEffect, useRef, useState } from "react";
import './user-settings.scss'
export default function UserSettings() {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isSmallScreen = smallScreen();
    const t = useLanguage();
    const dispatch = useDispatch()
    const isCurrentUserEffect = useRef(true)
    console.log('currentUser?.isAddBrokenLetter', currentUser?.isAddBrokenLetter);

    const [isAddBrokenLetter, setIsAddBrokenLetter] = useState(currentUser?.isAddBrokenLetter)
    console.log('isAddBrokenLetter', isAddBrokenLetter);

    const [isShowAllPopups, setIsShowAllPopups] = useState(false)
    const [isShowImageInNoErrorScan, setIsShowImageInNoErrorScana] = useState(currentUser?.isShowImageInNoErrorScan || false)

    useEffect(() => {
        if (currentUserId) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])

    const addBrokenLetterError = async (event) => {
        const isChecked = event.target.checked;
        setIsAddBrokenLetter(isChecked)
        await dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'isAddBrokenLetter', isChecked: isChecked }))
    }
    const showAllPopups = async (event) => {
        const isChecked = event.target.checked;
        if (isChecked == true) {
            setIsShowAllPopups(isChecked)
            await dispatch(showAllUserPopups({ userId: currentUserId, isShowPopups: !isChecked }))
        }
    }
    const setIsShowImageInNoErrorScan = async (event) => {
        const isChecked = event.target.checked;
        setIsShowImageInNoErrorScana(isChecked)
        await dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'isShowImageInNoErrorScan', isChecked: isChecked }))
    }

    return (
        <div >
            <Header page={'settings'} context={[t.navbar.settings]}></Header>
            <div className="user-settings-wrapper">
                <div>

                    <div className="row1 pt-4">
                        <input type="checkbox"
                            checked={isAddBrokenLetter}
                            onChange={(e) => addBrokenLetterError(e)}
                            style={{ width: '20px', height: '20px' }} // Adjust size as needed
                        ></input>
                        <div className={`add-broken-letter-text ${isSmallScreen ? 'mobile' : ''}`}>
                            הוספת התראה על ‘אות קטועה’ בדו״ח
                        </div>
                    </div>

                    <div className="row1 pt-4">
                        <input type="checkbox"
                            checked={isShowAllPopups}
                            onChange={(e) => showAllPopups(e)}
                            style={{ width: '20px', height: '20px' }} // Adjust size as needed
                        ></input>
                        <div className={`add-broken-letter-text ${isSmallScreen ? 'mobile' : ''}`}>
                            הצג שוב את כל תיבות העזרה
                        </div>
                    </div>

                    <div className="row1 pt-4">
                        <input type="checkbox"
                            checked={isShowImageInNoErrorScan}
                            onChange={(e) => setIsShowImageInNoErrorScan(e)}
                            style={{ width: '20px', height: '20px' }}
                        ></input>
                        <div className={`add-broken-letter-text ${isSmallScreen ? 'mobile' : ''}`}>
                            הצג את תמונת הסריקה כאשר הדו"ח נקי
                        </div>
                    </div>
                </div>


                <div className={`add-broken-letter-text ${isSmallScreen ? 'mobile' : ''}`}>
                    עוד הגדרות חשובות לנוחיותכם יתווספו בהמשך....
                </div>
            </div>

        </div>

    )
}