import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDocument, createTransactionWithToken, } from "../store/action";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentForm from "./PaymentForm";
import { getCurrentUserById } from "../../user/store/action";
import { ClipLoader } from "react-spinners";
import "./payment-style.scss";
import { BASE_URL, smallScreen, useLanguage } from "../../../utils/constants";
import axios from "axios";

const Payment = (props) => {
  const isSmallScreen = smallScreen();
  const language = useSelector((store) => store.userReducer.language);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fields, setFields] = useState(null);
  const [messages, setMessages] = useState([]);
  const [resMessage, setResMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector((store) => store.userReducer.currentUser);
  const currentUserId = JSON.parse(localStorage.getItem("currentUser"))?.id;
  const isCurrentUserEffect = useRef(true);
  const isLoad = useRef(false);
  const sumInStorage = JSON.parse(sessionStorage.getItem('sum'))?.sum
  const [sum, setSum] = useState(sumInStorage);
  const cuponCodeObject = JSON.parse(sessionStorage.getItem("sum"));
  const sumToBalace = JSON.parse(sessionStorage.getItem("sumToBalace"));
  const currency = sessionStorage.getItem("currency") || 'ILS';
  const t = useLanguage();
  const [disabled, setDisabled] = useState(false);
  const [isBit, setIsBit] = useState(false);

  useEffect(() => {
    setSum(sumInStorage)
  })

  useEffect(() => {
    try {
      if (currentUserId && !currentUser && isCurrentUserEffect.current) {
        dispatch(getCurrentUserById(currentUserId));
        isCurrentUserEffect.current = false;
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentUserId]);

  useEffect(() => {
    try {
      if (isLoad.current == false) {
        isLoad.current = true;
        if (fields === null) {
          console.log('💧💧💧💓💓💓');
          tzlHostedFields();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleCloseModal = () => {
    if (resMessage === t.paymentMessages.paymentSucceeded) {
      navigate("/dashboard");
    } else {
      setDisabled(false);
    }
    setShowModal(false);
  };

  const handleSubmit = async (event) => {
    console.log('handleSubmit', handleSubmit);
    try {
      event.preventDefault();
      setDisabled(true);
      chargeCcData(event);
    } catch (error) {
      console.log("", error);
    }
  };

  const chargeCcData = (e) => {
    console.log('chargeCcData🤩', e);
    setIsLoading(true);
    console.log('currency', currency);
    try {
      let responseHandled = false;
      fields.charge(
        {
          terminal_name: process.env.REACT_APP_TRANZILA_TERMINAL_NAME,
          amount: sum,
          currency_code: currency,
          response_language: process.env.REACT_APP_TRANZILA_RESPONSE_LANGUAGE,
          requested_by_user: process.env.REACT_APP_TRANZILA_TERMINAL_NAME,
          email: currentUser.email,
          contact: currentUser.customerName
        },
        (error, response) => {
          console.log("Callback invoked");
          // if (response) {
          if (response && !responseHandled) {
            responseHandled = true;
            console.log("response😯", response);
            parseResponse(response, 'credit card');
          }
          if (error) {
            console.log("error😨", error);
            if (error.messages !== null) {
              setDisabled(false);
              setIsLoading(false);
              console.log("error.messages☹️", error.messages);
              handleError(error);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleError = (err) => {
    try {
      console.log("handleError", err);
      let tmpArray = [];
      err.messages.forEach((message) => {
        tmpArray.push(message.param);
      });
      setMessages(tmpArray);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayWithBit = (event) => {
    try {
      setIsBit(true)
      // document.getElementById('hf-bit-iframe').style.display = '';
      // document.getElementById('hf-bit-cont').style.display = '';

      event.preventDefault();
      chargeBitData(event);
    }
    catch (error) {
      console.log(error);
    }
  }

  const chargeBitData = (e) => {
    try {
      console.log('start charging BIT', e);

      fields.chargeBit({
        terminal_name: process.env.REACT_APP_TRANZILA_TERMINAL_NAME,
        currency_code: 'ILS',
        amount: sum,
        email: currentUser.email,
        contact: currentUser.customerName,
        // transaction_layout: 'qr-sms',
        bit_language: 'hebrew',
        response_language: 'english',
      }, (err, response) => {
        console.log('err: ' + JSON.stringify(err));
        console.log('response in bit:' + response);
        if (err) {
          handleError(err);
          console.log('err', err);
          setResMessage(err.messages[0].message)
          setShowModal(true)
        }
        if (response) {
          parseResponse(response, 'bit');
          console.log('bit response#️⃣#️⃣#️⃣', response);
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  const payFunc = async (hostedFildesResponse, paymentOption) => {
    console.log('payFunc🙏🙏🙏🙏', hostedFildesResponse, paymentOption);

    try {
      const transactionObj = {
        userId: currentUserId,
        email: currentUser.email,
        sumToBalace: sumToBalace,
        paymentOption: paymentOption,
        hostedFildesResponse: hostedFildesResponse
      };
      console.log("transactionObj", transactionObj);
      const res = await dispatch(createTransactionWithToken(transactionObj));
      await dispatch(getCurrentUserById(currentUserId));
      console.log("res of createTransactionWithToken ", res);
      if (res != undefined && res.status == '000') {
        // createDocumentFunc(hostedFildesResponse);
        console.log('cuponCodeObject', cuponCodeObject);
        console.log('cuponCodeUse', cuponCodeObject.cuponCodeUse);

        if (cuponCodeObject.cuponCodeUse == true) {
          setIsLoading(false);
          setShowModal(true);
          saveCuponCodeUse()
        }

        return true;
      }
      return false;
    }
    catch (error) {
      console.log(error);
      return false;
    }
    finally {
      setIsLoading(false);
      setShowModal(true);
    }
  };
  const saveCuponCodeUse = async () => {
    const object = {
      userId: currentUserId,
      cuponCodeName: cuponCodeObject.cuponCodeName,
      userName: currentUser.customerName,
      sum: cuponCodeObject.sum,
      sumBeforeCupon: cuponCodeObject.sumBeforeCupon
    }
    console.log('object👀😂👀😂', object);

    console.log('cuponCodeObject💧💧💧', object);
    //todo post this just after payment succeed
    const response = await axios.post(`${BASE_URL}/api/cuponCodeUses/add/`, object);
    console.log('response', response);

  }

  const parseResponse = async (response, paymentOption) => {
    console.log('parseResponse', response, paymentOption);
    try {
      if (response.errors != null) {
        const errCode = response.errors[0].code;
        console.log('response.errors', response.errors);

        const errMessage = t.tranzilaErrMessages[errCode] || t.tranzilaErrMessages[0];
        console.log('errMessage', errMessage);
        setResMessage(errMessage);
        setDisabled(false);
        setIsLoading(false);
        setShowModal(true);
      }
      else {
        if (response.transaction_response.success === false) {
          console.log('response.transaction_response.error', response.transaction_response.error);
          setResMessage(response.transaction_response.error);
          setIsLoading(false);
          setShowModal(true);
        } else {
          console.log('12312312313132123131322313112331233123');

          //now tranzila payments succeeded-so send my function
          const bool = await payFunc(response, paymentOption);
          console.log('bool', bool);
          setResMessage(bool ? t.paymentMessages.paymentSucceeded : t.paymentMessages.paymentFailed);
        }
      }
      console.log("resMessage", resMessage);
    } catch (error) {
      console.log(error);
      setResMessage(error);
    }
  };

  const validityChange = (result) => {
    try {
      const tmpArray = messages?.slice();
      if (result && result.field) {
        tmpArray.splice(result.field.index, 1);
      }
      setMessages(tmpArray);
    } catch (error) {
      console.log(error);
    }
  };

  const tzlHostedFields = () => {
    try {
      const tzlFields = window.TzlaHostedFields.create({
        sandbox: false,
        fields: {
          card_holder_id_number: {
            selector: "#card_holder_id_number",
            placeholder: t.payment.cardHolderId,
            tabIndex: 2,
          },
          credit_card_number: {
            selector: "#credit_card_num",
            placeholder: t.payment.cardNumber,
            tabIndex: 1,
          },
          cvv: {
            selector: "#cvv",
            placeholder: t.payment.cvvShort,
            tabIndex: 4,
          },
          expiry: {
            selector: "#expiry",
            placeholder: t.payment.expiryPlaceholder,
            tabIndex: 3,
            version: "1",
          },
        },
      });
      tzlFields.onEvent("blur", validityChange);
      setFields(tzlFields);
    } catch (error) {
      console.log(error);
    }
  };
  const closeBit = () => {
    console.log('closeBit');
    document.getElementById('hf-bit-iframe').style.display = 'none';
    document.getElementById('hf-bit-cont').style.display = 'none';
    setIsBit(false)
  }
  return (
    <>
      {isBit == true && <button className='qqqqqqqq' onClick={closeBit}>
        x
      </button>
      }
      <PaymentForm
        handleSubmit={handleSubmit}
        disabled={disabled}
        msg={messages}
        obj={location.state}
        sum={setSum}
        payWithBit={handlePayWithBit}
      />
      {isLoading && (
        <div className="spinner-container ">
          <div style={currentLanguage == 'en-US' ? { margin: '10px 0 10px 16rem' } : { margin: '10px 16rem 10px 0' }}>
            <ClipLoader size={80} color={"#007bff"} loading={isLoading} />
          </div>
          <div className="background-overlay" />
        </div>
      )}

      <Modal
        className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body className="pdf-modal">
          <Modal.Title>{resMessage}</Modal.Title>
          <button className="btn btn-primary mt-4" onClick={handleCloseModal}>
            {t.paymentMessages.ok}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Payment;