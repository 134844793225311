import React, { useEffect, useReducer, useRef, useState } from 'react';
import { BASE_URL, smallScreen, useLanguage } from '../../../utils/constants';
import Header from '../../header/header';
import './report-page-style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { createReportSortByType, fetchReportByProjectId, setConfirmedScansIds, setCoords, setImageUrl, setSortedReport, setSortReportOcroutput, updateReport } from '../store/action';
import ViewImage from '../../../utils/file-azure/view-image';
import ImageCropper from '../../../utils/crop-image/crop-image';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ShowImage from '../../../utils/show-image/show-image';
import ImageCanvas from '../../../utils/show-image/show-image';
// import ShowImage from '../../../utils/show-image/show-image';
import ViewPdf from '../../../utils/pdf/pdf-modal';
import ReactGA from 'react-ga4';
import Spinner from '../../../utils/spinner/spinner';
import { getCurrentUserById, updateUserDontShowAgain } from '../../user/store/action';
import { useLocation, useNavigate } from 'react-router-dom';
import smallLogo from '../../../utils/assets/small-logo.svg'
const ReportPage = () => {
    const isSmallScreen = smallScreen();
    const dispatch = useDispatch();
    const t = useLanguage();
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const currentProjectId = JSON.parse(sessionStorage.getItem('currentProjectId'));
    const reportStore = useSelector((store) => store.reportReducer);
    const reportFullObject = reportStore.report || {};
    const sortedReport = reportStore.reportOcroutputSort || {};
    const [report, setReport] = useState(sortedReport)
    const location = useLocation();
    const showPopup = location?.state?.showPopup
    const [reportId, setReportId] = useState('')
    let keyValueArray = JSON.parse(sessionStorage.getItem('keyValueReportArray')) || [];
    const language = useSelector((store) => store.userReducer.language);
    const currentProjectName = JSON.parse(sessionStorage.getItem('currentProjectName'));
    const isAddBrokenLetter = currentUser?.isAddBrokenLetter || false;
    const scanType = JSON.parse(sessionStorage.getItem('scanType'));
    const isEffect = useRef(true);
    const index = useRef(-1);
    const [disabledCreateReport, setDisabledCreateReport] = useState(false);
    const scanId = useRef('');
    const imageId = useRef('');
    const isImageEffect = useRef(false);
    const isShowRef = useRef(false);
    const coordinates = useRef({});
    let indexesObject = JSON.parse(localStorage.getItem('indexesToUpdateObject')) || {};
    const indexesToUpdate = useRef({});
    const originalImageWidth = useRef(0);
    const originalImageHeight = useRef(0);
    let confirmedScansIds = reportStore.confirmedScansIds;
    const [sortedReportByTypeValues, setSortedReportByTypeValues] = useState({});
    const [sortedReportByTypeKeys, setSortedReportByTypeKeys] = useState([]);
    const [lineErrorClasses, setLineErrorClasses] = useState({});
    const defaultCoords = { x: 0, y: 0, width: 0, height: 0 };
    const errorTypesTranslate = t.errorsType;
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [chekcboxPress, setChekcboxPress] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [isShowPdf, setIsShowPdf] = useState('');
    const [isShow, setIsShow] = useState(false);
    const [spinner, setSpinner] = useState(0)
    const [showOriginalImage, setShowOriginalImage] = useState(false)
    const [disabledImage, setDisabledImage] = useState(false)
    const imageUrl = useRef('');
    const URL = BASE_URL;
    const [checkboxStates, setCheckboxStates] = useState({});
    const [checkAllCheckboxStates, setCheckAllCheckboxStates] = useState(false);
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const buttonRef = useRef(null);
    const buttonRef2 = useRef(null);
    const isCurrentUserEffect = useRef(true);
    const isReportEffect = useRef(true)
    const isLoadPageEffect = useRef(true)
    const [isShowList, setIsShowList] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [scanNotConfirmedModal, setScanNotConfirmedModal] = useState(false);
    const [indexValue, setIndexValue] = useState(1);
    const [ocrText, setOcrText] = useState([]);
    const [errorLine, setErrorLine] = useState(0);
    const timerRef = useRef(null);
    const isEffectRef = useRef(false);
    const scansIdsToReport = JSON.parse(sessionStorage.getItem('scansIdsToReport'));
    const navigate = useNavigate()
    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    useEffect(() => {
        async function fetchReportsFunc() {
            isLoadPageEffect.current = false;
            const resReport = await dispatch(fetchReportByProjectId({ userId: currentUserId, projectId: currentProjectId }))
            setReportId(resReport._id)
            const updatedReport = {
                ...resReport,
                ocrOutput: resReport.ocrOutput?.filter((scan) => scansIdsToReport.includes(scan.scanId))
            };
            await dispatch(createReportSortByType(updatedReport));
            await dispatch(setSortReportOcroutput(updatedReport));

            changeIndex();
            setReport(updatedReport)
        }

        if (isLoadPageEffect.current == true) {
            fetchReportsFunc();
        }

    }, [report]);

    useEffect(() => {
        if (isEffectRef.current == false) {
            if (reportId) {
                isEffectRef.current = true
                const array = Object.keys(indexesObject[reportId] || {})
                    .filter(scanId => scansIdsToReport.includes(scanId)) // Keep only relevant scanIds
                    .map(scanId => ({ [scanId]: indexesObject[reportId][scanId] }));

                // Flatten the array of objects into a single object
                const result = array.reduce((acc, item) => {
                    return { ...acc, ...item };
                }, {});

                indexesToUpdate.current = result;
                let scansIds = Object.keys(result);

                dispatch(setConfirmedScansIds(scansIds))
                if (scansIds.length > 0)
                    changeIndex(scansIds.length - 1)
            }
        }
    }, [reportId])

    useEffect(() => {
        const fetchData = async () => {
            if (currentProjectId && currentUserId && isReportEffect.current) {
                await dispatch(fetchReportByProjectId({ userId: currentUserId, projectId: currentProjectId }))
                isReportEffect.current = false;
            }
        }
        fetchData();
    }, [currentProjectId])

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    const changeIndex = (ind, isScanConfirmedNow) => {
        isImageEffect.current = false;
        dispatch(setImageUrl(''))
        const isScanConfirmed = isScanConfirmedNow == true ? true : confirmedScansIds.includes(scanId.current);

        if (index.current > 0 && !isScanConfirmed && !scanNotConfirmedModal && ([undefined, false].includes(currentUser?.dontShowAgainReportPage))) {
            setIndexValue(ind == -1 ? -1 : 1)
            setDisabledCreateReport(false)
            setSpinner(0)
            setScanNotConfirmedModal(true)
        }
        else {
            setScanNotConfirmedModal(false)
            const indexToChange = ind || 1;
            keyValueArray = JSON.parse(sessionStorage.getItem('keyValueReportArray')) || [];
            if (isAddBrokenLetter == false) {
                keyValueArray.forEach((object) => {
                    if (object[1]['BROKEN_LETTER']) {
                        delete object[1]['BROKEN_LETTER'];
                    }
                });
            }

            if (keyValueArray.length > 0 && index.current + indexToChange < keyValueArray.length && index.current + indexToChange >= 0) {
                index.current = index.current + indexToChange;
                const currentKeyValueArray = keyValueArray[index.current];

                setSortedReportByTypeValues(currentKeyValueArray[1]);
                if (currentKeyValueArray[1]) {
                    setSortedReportByTypeKeys(Object.keys(currentKeyValueArray[1]));
                }
                else {
                    console.log("null value")
                }
                scanId.current = currentKeyValueArray[0];
                // const scanData = indexesObject[scanId.current] || [];
                // indexesToUpdate.current[scanId.current] = scanData

                sortedReportByTypeKeys.map(key =>
                    // areAllIndexesUpdated(key)                    
                    setCheckboxStates((prevStates) => ({
                        ...prevStates,
                        [key]: false,
                    }))
                )
                setCheckAllCheckboxStates(false)
            }
            console.log('dddddddddddddddddddd');

        }

    }

    const areAllIndexesUpdated = (errorKey) => {
        const expectedIndexes = sortedReportByTypeValues[errorKey]?.map((item, ind) =>
            item.indexesInOriginalArray
        );
        const areAllInIndexesToUpdate = expectedIndexes.every((expectedIndex) => {
            return indexesToUpdate?.current[scanId.current] != undefined && indexesToUpdate?.current[scanId.current]?.indexOf(expectedIndex) != -1
        }
        );
        setCheckboxStates((prevStates) => ({
            ...prevStates,
            [errorKey]: areAllInIndexesToUpdate,
        }))
    };

    const handleErrorCheckboxChange = async (event, errorKey) => {
        const checkbox = event.target;

        const updatedClasses = { ...lineErrorClasses };

        if (checkbox.checked) {
            if (!indexesToUpdate.current[scanId.current])
                indexesToUpdate.current[scanId.current] = [];

            sortedReportByTypeValues[errorKey].map((item, ind) => {
                const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                updatedClasses[errorIndexesToCalss] = 'line-error-checked';
                delete updatedClasses[errorIndexesToCalss + '-unchecked'];

                const errorIndexes = item.indexesInOriginalArray;
                indexesToUpdate.current[scanId.current] = [
                    ...indexesToUpdate.current[scanId.current],
                    errorIndexes,
                ];
            });

            setCheckboxStates((prevStates) => ({
                ...prevStates,
                [errorKey]: true,
            }))
        }
        else {
            sortedReportByTypeValues[errorKey].map((item, ind) => {
                const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                updatedClasses[errorIndexesToCalss] = 'line-error';
                delete updatedClasses[errorIndexesToCalss + '-checked'];

                const errorIndexes = item.indexesInOriginalArray;
                const arr = indexesToUpdate.current[scanId.current]?.filter(
                    (subArray) => !arraysAreEqual(subArray, errorIndexes)
                );
                indexesToUpdate.current[scanId.current] = arr;
            })

            setCheckboxStates((prevStates) => ({
                ...prevStates,
                [errorKey]: false,
            }))

        }
        setLineErrorClasses(updatedClasses);
        isScanStillConfirmed();
    }

    const handleCheckboxChange = (event, errorIndexes, key, errorKey) => {
        console.log('handleCheckboxChange');

        const checkbox = event.target;
        const updatedClasses = { ...lineErrorClasses };

        if (checkbox.checked) {
            updatedClasses[key] = 'line-error-checked';
            delete updatedClasses[key + '-unchecked'];
            if (!indexesToUpdate.current[scanId.current])
                indexesToUpdate.current[scanId.current] = [];
            indexesToUpdate.current[scanId.current] = [
                ...indexesToUpdate.current[scanId.current],
                errorIndexes,
            ];

            areAllIndexesUpdated(errorKey);
        }
        else {
            updatedClasses[key] = 'line-error';
            delete updatedClasses[key + '-checked'];
            const arr = indexesToUpdate.current[scanId.current]?.filter(
                (subArray) => !arraysAreEqual(subArray, errorIndexes)
            );
            indexesToUpdate.current[scanId.current] = arr;

            setCheckboxStates((prevStates) => ({
                ...prevStates,
                [errorKey]: false,
            }))
        }
        setLineErrorClasses(updatedClasses);
        isScanStillConfirmed();
    };

    const isScanStillConfirmed = () => {
        if (indexesObject[reportId] && indexesObject[reportId][scanId.current]) {
            if (indexesToUpdate.current[scanId.current]?.length != indexesObject[reportId][scanId.current]?.length)
                confirmScan({ checked: false })
        }

    }

    const arraysAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }

    const confirmScan = (checkbox) => {
        // const checkbox = e.target;
        if (checkbox.checked) {
            dispatch(setConfirmedScansIds([...confirmedScansIds, scanId.current]))
            let indexesObject = JSON.parse(localStorage.getItem('indexesToUpdateObject')) || {};
            if (!indexesObject[reportId])
                indexesObject[reportId] = {}
            indexesObject[reportId][scanId.current] = indexesToUpdate.current[scanId.current] || [];
            localStorage.setItem('indexesToUpdateObject', JSON.stringify(indexesObject));
            changeIndex(1, true)
        }
        else {
            const filteredArray = confirmedScansIds?.filter(id => id !== scanId.current);
            dispatch(setConfirmedScansIds(filteredArray));

            let indexesObject = JSON.parse(localStorage.getItem('indexesToUpdateObject')) || {};
            if (indexesObject[reportId].hasOwnProperty(scanId.current)) {
                delete indexesObject[reportId][scanId.current];
            }
            localStorage.setItem('indexesToUpdateObject', JSON.stringify(indexesObject));
        }
    }

    const confirmScanMobile = () => {
        const isScanId = confirmedScansIds.find(id => id === scanId.current)
        if (isScanId) {
            const filteredArray = confirmedScansIds?.filter(id => id !== scanId.current);
            dispatch(setConfirmedScansIds(filteredArray));

            let indexesObject = JSON.parse(localStorage.getItem('indexesToUpdateObject')) || {};
            if (indexesObject[reportId].hasOwnProperty(scanId.current)) {
                delete indexesObject[reportId][scanId.current];
            }
            localStorage.setItem('indexesToUpdateObject', JSON.stringify(indexesObject));

        }
        else {
            dispatch(setConfirmedScansIds([...confirmedScansIds, scanId.current]))
            let indexesObject = JSON.parse(localStorage.getItem('indexesToUpdateObject')) || {};
            if (!indexesObject[reportId])
                indexesObject[reportId] = {}
            indexesObject[reportId][scanId.current] = indexesToUpdate.current[scanId.current] || [];
            localStorage.setItem('indexesToUpdateObject', JSON.stringify(indexesObject));
            changeIndex(1, true)
        }
    }

    useEffect(() => {
        confirmedScansIds = reportStore.confirmedScansIds;
    }, [reportStore.confirmedScansIds])


    const showErrorOnImage = async (indexesInOriginalArray) => {
        const line = indexesInOriginalArray[0];
        const word = indexesInOriginalArray[1];
        const letter = indexesInOriginalArray[2] >= 0 ? indexesInOriginalArray[2] : null;
        let error;

        if (letter != null) {
            report.ocrOutput.map((item) => {
                if (item.scanId == scanId.current) {
                    error = item.lines[line]?.words[word]?.letters[letter];
                    const text = []
                    text.push({ text: '"', class: 'regular-text' })
                    item.lines[line].words.map((wordObject, index) => {
                        if (index == word) {
                            text.push({ text: '', class: 'padding' })
                            wordObject.letters.map((letterObject, letterIndex) => {
                                if (letterIndex == letter) {
                                    text.push({ text: letterObject.orig_text, class: 'bold-text1' })
                                }
                                else
                                    text.push({ text: letterObject.orig_text, class: 'regular-text' })
                            })
                        }
                        else
                            text.push({ text: wordObject.orig_text, class: 'regular-text padding' })
                    })
                    text.push({ text: '"', class: 'regular-text' })
                    setOcrText(text)
                    setErrorLine(line+1)
                    originalImageWidth.current = item.width;
                    originalImageHeight.current = item.height;
                }
            })
        }
        else {
            report.ocrOutput.map((item) => {
                if (item.scanId == scanId.current) {
                    error = item.lines[line]?.words[word];
                    const text = []
                    text.push({ text: '"', class: 'regular-text' })
                    item.lines[line].words.map((wordObject, index) => {
                        console.log('word', wordObject.orig_text);
                        if (index == word)
                            text.push({ text: wordObject.orig_text, class: 'bold-text1 padding' })
                        else
                            text.push({ text: wordObject.orig_text, class: 'regular-text padding' })
                    })
                    text.push({ text: '"', class: 'regular-text' })
                    setOcrText(text)
                    originalImageWidth.current = item.width;
                    originalImageHeight.current = item.height;
                }
            })
        }

        const coords = error.coords || defaultCoords;

        coordinates.current = {
            x: (coords.x),
            y: (coords.y),
            width: (coords.width),
            height: (coords.height)
        }

        await dispatch(setCoords(coordinates.current))
        if (imageId.current && coordinates.current) {
            if (!chekcboxPress) {
                navigate('/report-page?type=image')
                setIsImageModalVisible(true);
            }
        }

    }

    const chooseAll = async (event) => {
        const checkbox = event.target;

        const updatedClasses = { ...lineErrorClasses };

        if (checkbox.checked) {
            setCheckAllCheckboxStates(true)
            if (!indexesToUpdate.current[scanId.current])
                indexesToUpdate.current[scanId.current] = [];

            sortedReportByTypeKeys.map((errorKey) => {
                sortedReportByTypeValues[errorKey].map((item, ind) => {
                    const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                    updatedClasses[errorIndexesToCalss] = 'line-error-checked';
                    delete updatedClasses[errorIndexesToCalss + '-unchecked'];

                    const errorIndexes = item.indexesInOriginalArray;
                    indexesToUpdate.current[scanId.current] = [
                        ...indexesToUpdate.current[scanId.current],
                        errorIndexes,
                    ];
                });

                setCheckboxStates((prevStates) => ({
                    ...prevStates,
                    [errorKey]: true,
                }))

            })

        }
        else {
            setCheckAllCheckboxStates(false)
            sortedReportByTypeKeys.map((errorKey) => {
                sortedReportByTypeValues[errorKey].map((item, ind) => {
                    const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                    updatedClasses[errorIndexesToCalss] = 'line-error';
                    delete updatedClasses[errorIndexesToCalss + '-checked'];

                    const errorIndexes = item.indexesInOriginalArray;
                    const arr = indexesToUpdate.current[scanId.current]?.filter(
                        (subArray) => !arraysAreEqual(subArray, errorIndexes)
                    );
                    indexesToUpdate.current[scanId.current] = arr;
                })
                setCheckboxStates((prevStates) => ({
                    ...prevStates,
                    [errorKey]: false,
                }))
            })



        }
        setLineErrorClasses(updatedClasses);
        isScanStillConfirmed();

    }

    const createReport = async () => {
        setIsShowConfirmModal(false);
        setDisabledCreateReport(true);
        setSpinner(100)
        const filteredArray = {};
        if (report.ocrOutput.length > 1) {
            console.log('report.ocrOutput.length > 1');
            confirmedScansIds.forEach(scanId => {
                filteredArray[scanId] = indexesToUpdate.current[scanId] || [];
            });
        }
        else {
            const firstScanId = report.ocrOutput[0].scanId;
            confirmedScansIds = [firstScanId]
            filteredArray[firstScanId] = indexesToUpdate.current[firstScanId] || [];
        }
        console.log('filteredArray', filteredArray);
        console.log('confirmedScansIds', confirmedScansIds);
        const pdfReport = await dispatch(updateReport({ reportId: report._id, indexesToUpdate: filteredArray, scansIdsToPdf: confirmedScansIds, locale: language, scanType: t.scanTypesSingle[scanType], isShowImageInNoErrorScan: currentUser?.isShowImageInNoErrorScan }))
        console.log('pdfReport', pdfReport);

        setDisabledCreateReport(false);
        if (pdfReport) {
            setIsShowPdf(pdfReport);
            dispatch(setConfirmedScansIds([]));
            setLineErrorClasses([]);
            setCheckboxStates({});
            setCheckAllCheckboxStates(false)
            const scansIdsToReport = JSON.parse(sessionStorage.getItem('scansIdsToReport'));
            const updatedReport = {
                ...report,
                ocrOutput: report.ocrOutput?.filter((scan) => scansIdsToReport.includes(scan.scanId))
            };
            if (indexesObject[reportId]) {
                delete indexesObject[reportId];
                localStorage.setItem('indexesToUpdateObject', JSON.stringify(indexesObject));
            }
            indexesToUpdate.current = {};
            const allIndexes = await dispatch(createReportSortByType(updatedReport));
            // indexesToUpdate.current = allIndexes;
        }
        else {
            alert('תקלה')
        }

    }

    useEffect(() => {
        setDisabledImage(true)
        setShowOriginalImage(false);
        imageId.current = ''
        const foundImage = report?.images?.find(item => item.scanId === scanId.current);
        if (foundImage) {
            imageId.current = foundImage._id || '';
        }
        const currentImageUrl = `https://stamimages.blob.core.windows.net/scans-images/scan${scanId.current}.jpg`;

        if (isImageEffect.current == false && imageId.current != '') {
            isImageEffect.current = true;

            axios
                // .get(currentImageUrl, { responseType: 'arraybuffer' })
                .get(`${BASE_URL}/api/file/download/${imageId.current}`, { responseType: 'arraybuffer' })
                .then((response) => {
                    const url = (window.webkitURL || URL).createObjectURL(new Blob([response.data]));
                    imageUrl.current = url;
                    setShowOriginalImage(true);
                    // dispatch(setImageUrl(currentImageUrl))
                    dispatch(setImageUrl(imageUrl.current))
                    if (showPopup && isShowRef.current == false && currentUser?.dontShowAgainFilterAlert == false) {
                        isShowRef.current = true;
                        setIsShow(true)
                    }
                    setDisabledImage(false)

                })
                .catch((error) => {
                    setDisabledImage(false)
                    console.log('error in view image😭😭')
                })
        }

    }, [scanId.current]);

    useEffect(() => {
        if (index.current === -1 && !report) {
            window.location.reload();
        }
    }, [index])

    useEffect(() => {
        buttonRef?.current?.focus();

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                if (confirmedScansIds.length > 0) {
                    openConfirmModal()
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };

    }, [confirmedScansIds]);

    useEffect(() => {
        if (isShowConfirmModal) {

            buttonRef2.current.focus();

            const handleKeyPress = (event) => {
                if (event.key === 'Enter') {
                    createReport()
                }
            };

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }

    }, [isShowConfirmModal]);

    const openConfirmModal = () => {
        setIsShowConfirmModal(true)
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'לחיצה על הפק דוח סיכום בעמוד סינון שגיאות',
                label: 'עמוד סינון שגיאות',
                value: 1
            });

    }

    useEffect(() => {
        setIsShowList(true);
        timerRef.current = setTimeout(() => {
            setIsShowList(false);
        }, 10000);
        return () => clearTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        if (sortedReportByTypeKeys?.length >= 1) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            setIsShowList(false);
        }
    }, [sortedReportByTypeKeys]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % 3);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const dontShowAgainReportPage = (event) => {
        const checkbox = event.target;

        if (checkbox.checked) {
            dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'dontShowAgainReportPage' }))
        }
    }

    const dontShowAgainFilterAlert = (event) => {
        const checkbox = event.target;

        if (checkbox.checked) {
            dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'dontShowAgainFilterAlert' }))
        }
    }

    useEffect(() => {
        const handlePopState = () => {
            if (window.location.pathname === '/report-page') {
                setIsImageModalVisible(false);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    return (
        <div className={isSmallScreen ? 'report-page-container' : 'container report-page-container'}>
            <div className='col'>

                <Header page={'upload-scan'} context={[t.report.report, ">", t.scanTypes[scanType], ">", currentProjectName, '']}></Header>

                {/* header */}
                <div className='change-index '>
                    <div type='button' className='arrow-color left' onClick={() => changeIndex(-1, false)}>{`<`}</div>
                    {currentLanguage == 'en-US' ?
                        <div className={`text-center `}>{`${t.scanTypes[scanType]} ${index.current + 1} / ${report?.ocrOutput?.length || 0}`}</div> :
                        <div className={`text-center `}>{`${t.scanTypes[scanType]} ${report?.ocrOutput?.length || 0} / ${index.current + 1}`}</div>
                    }
                    <div type='button' className='arrow-color ' onClick={() => changeIndex(1, false)}>{`>`}</div>
                </div>
                <div>{t.report.reportMessage}</div>

                <div className={`${isSmallScreen ? '' : 'report-footer'} ${disabledCreateReport == true ? '' : isSmallScreen ? 'sticky-div-mobile' : 'sticky-div'}`}>
                    <div className={'footer-container '}>
                        <div type='button' className={`confirm-scan mobile ${confirmedScansIds.includes(scanId.current) ? 'checked ' : ''}`} onClick={confirmScanMobile}>
                            {t.report.confirmScanEdit + ' ' + t.scanTypesSingle[scanType] + ' ' + String(index.current + 1)}
                        </div>

                        <div type='button' ref={buttonRef} className={`edit-report-btn  ${confirmedScansIds.length == 0 ? ' disabled' : ''} ${isSmallScreen ? 'mobile' : ''} `} disabled={confirmedScansIds.length === 0} onClick={confirmedScansIds.length > 0 ? openConfirmModal : ''} >
                            <div className={`confirm-scan-text ${isSmallScreen ? 'mobile' : ''} ${confirmedScansIds.length == 0 ? 'disabled' : ''}`}>{t.scans.createReport}</div>
                        </div>
                    </div>

                    <div className='report-choose-all-container'>
                        <div className={`choose-all-wrapper report-choose-all  ${currentLanguage == 'en-US' ? 'ltr' : ''} `}>
                            <input
                                type="checkbox"
                                className='scan-checkbox'
                                onChange={(e) => { chooseAll(e) }}
                                checked={checkAllCheckboxStates}
                            />
                            <div className={`choose-all`}>{t.scan.chooseAll}</div>
                        </div>
                    </div>

                </div>

                {/* errors maping */}
                {/* <div className={`scrollable-div report ${isSmallScreen ? 'mobile' : ''}`}> */}
                <div>
                    {isShowList || disabledImage ?
                        <div className={`slider padding ${isSmallScreen ? 'mobile' : ''}`}>
                            <div className={`dot ${activeIndex === 0 ? 'active' : ''}`}></div>
                            <div className={`dot ${activeIndex === 1 ? 'active' : ''}`}></div>
                            <div className={`dot ${activeIndex === 2 ? 'active' : ''}`}></div>
                        </div>
                        :
                        sortedReportByTypeKeys?.length >= 1 ?
                            (sortedReportByTypeKeys.map((key) => (
                                <div key={key}>
                                    {sortedReportByTypeValues[key].length > 0 &&
                                        <div className={`card-custom-report ${isSmallScreen ? 'card-mobile-report' : 'card report'}`}
                                            // data-toggle="collapse"
                                            // data-target={`#collapse-${key}`}
                                            alt="Errow Up"
                                            type='button'
                                        >
                                            <div onClick={(e) => e.stopPropagation()} >
                                                <input
                                                    id={`error-checkbox-${key}`}
                                                    type="checkbox"
                                                    className='scan-checkbox'
                                                    onChange={(e) => { e.stopPropagation(); handleErrorCheckboxChange(e, key) }}
                                                    checked={checkboxStates[key]}
                                                />
                                            </div>
                                            <div style={{ color: 'black' }}>{errorTypesTranslate[key]} {'(' + sortedReportByTypeValues[key].length + ')'}</div>
                                        </div>
                                    }
                                    <div
                                        //  id={`collapse-${key}`} 
                                        className={` ${isSmallScreen ? 'container-error-mobile' : ` container-error`}`}>
                                        {/* className={`collapse ${isSmallScreen ? 'container-error-mobile' : ` container-error`}`}> */}
                                        {sortedReportByTypeValues[key].map((item, ind) => (
                                            <div key={key + ' ' + ind}>
                                                <div
                                                    id={`${index.current}-${key}-${ind}`}
                                                    type='button'
                                                    className={indexesToUpdate.current[scanId.current]?.some(subArray => JSON.stringify(subArray) === JSON.stringify(item.indexesInOriginalArray)) ? 'line-error-checked' : 'line-error'}
                                                    // className={lineErrorClasses[`${index.current}-${key}-${ind}`] || 'line-error'}
                                                    onClick={(e) => {
                                                        if (e.target.type !== 'checkbox') {
                                                            e.stopPropagation();
                                                            showErrorOnImage(item.indexesInOriginalArray);
                                                        }
                                                    }}
                                                >
                                                    {/* {['MISSING_WORD', 'MISSING_LETTER', 'ALTERED_WORD', 'LETTER_SHAPE_CHANGED'].includes(key) ? item.value.orig_text : item.value.ocr_text} */}
                                                    <ImageCropper coordinates={item.coords} originalImageWidth={item.width} originalImageHeight={item.height} showOriginalImage={showOriginalImage}></ImageCropper>
                                                    {/* todo uncheck them defualtly */}
                                                    <input
                                                        type="checkbox"
                                                        className='checkbox'
                                                        checked={indexesToUpdate.current[scanId.current]?.some(subArray => JSON.stringify(subArray) === JSON.stringify(item.indexesInOriginalArray))}
                                                        // checked={lineErrorClasses[`${index.current}-${key}-${ind}`] == 'line-error-checked' ? true : false}
                                                        onChange={(e) => { e.stopPropagation(); handleCheckboxChange(e, item.indexesInOriginalArray, `${index.current}-${key}-${ind}`, key); }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div >
                            )))
                            :
                            <div className='no-error-text'>{t.report.noErrors}</div>
                    }
                </div>

                {/* view image modal */}
                <Modal
                    show={isImageModalVisible}
                    onHide={() => { setIsImageModalVisible(false); navigate('/report-page') }}
                    centered
                    dialogClassName={`${(scanType == 'tefillin') ? isSmallScreen ? 'my-modal-tefillin-mobile' : 'my-modal-tefillin' : `modal ${isSmallScreen ? '' : 'right-margin'} ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
                >
                    <div className='no-padding-body'>
                        {/* <Modal.Body > */}
                        <div className='row-text'>
                            {/* todo */}
                            {`${t.report.line} ${errorLine}: `}
                            {ocrText && ocrText.map((item) => {
                                return (
                                    <div className={`original-text ${item.class}`}>{item.text}</div>
                                )
                            })}
                        </div>
                        <ImageCanvas width={originalImageWidth.current} height={originalImageHeight.current} />
                        {/* </Modal.Body> */}
                    </div>

                </Modal>

            </div>

            {/*confirm modal */}
            <Modal
                show={isShowConfirmModal}
                onHide={() => { setIsShowConfirmModal(false) }}
                centered
                className={`modal ${isSmallScreen ? 'buttom-modal' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Body>
                    <div type='button' className={`are-you-sure`} >
                        <div className={`back-text ${isSmallScreen ? 'mobile' : ''}`}>
                            {t.uploadScans.areYouSure}
                        </div>
                        {report?.ocrOutput?.length > 1 && <div>
                            {`${t.report.fyi} ${confirmedScansIds.length < report?.ocrOutput?.length ? 'רק' : ''} ${confirmedScansIds.length}/${report?.ocrOutput?.length} ${t.scanTypes[scanType]}`}
                        </div>}
                    </div>
                    <div className={`frame-b ${isSmallScreen ? 'mobile' : ''} ${currentLanguage == 'en-US' ? '' : 'reverse'}`}>
                        <div type="button" ref={buttonRef2} className={`btn-ok ${isSmallScreen ? 'mobile' : ''} col-6 `} onClick={createReport}>
                            <div className={`btn-ok-text ${isSmallScreen ? 'mobile' : ''}`} >
                                {t.uploadScans.ok}
                            </div>
                        </div>
                        <div type="button" className={`btn-cancel ${isSmallScreen ? 'mobile' : ''} col-6`} onClick={() => { setIsShowConfirmModal(false) }}>
                            <div className={`btn-ok-text cancel ${isSmallScreen ? 'mobile' : ''} `} >{t.uploadScans.cancel}</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* are you sure modal */}
            <Modal
                show={scanNotConfirmedModal}
                onHide={() => { setScanNotConfirmedModal(false) }}
                centered
                className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
            >
                <Modal.Body>
                    <div
                        type="button"
                        className={isSmallScreen ? "are-you-sure mobile" : "are-you-sure"}
                    >
                        <div className={`back-text ${isSmallScreen ? "mobile" : ""}`}>
                            <div>{t.report.scanNotConfirmed}</div>
                        </div>
                    </div>
                    <div className={`frame-b ${isSmallScreen ? "mobile" : ""} ${currentLanguage == "en-US" ? "" : "reverse"}`}>
                        <div
                            type="button"
                            className={isSmallScreen ? "btn-ok mobile col-6 " : "btn-ok col-6"}
                            onClick={() => changeIndex(indexValue)}
                        >
                            <div className="ok-button">
                                <div>{indexValue == -1 ? t.report.prevScan : t.report.nextScan}</div>
                            </div>
                        </div>
                        <div
                            type="button"
                            className={isSmallScreen ? "btn-cancel mobile col-6" : "btn-cancel col-6"}
                            onClick={() => { setScanNotConfirmedModal(false); }}
                        >
                            <div className={isSmallScreen ? "btn-ok-text cancel mobile " : "btn-ok-text cancel"}>
                                חזור לעריכה
                            </div>
                        </div>
                    </div>
                    <div className="dont-show">
                        {t.uploadScans.dontShowAgain}
                        <input type="checkbox" onChange={(e) => dontShowAgainReportPage(e)}></input>
                    </div>
                </Modal.Body>
            </Modal>

            {/* view pdf modal */}
            <ViewPdf isShowPdf={isShowPdf} setIsShowPdf={setIsShowPdf} reportId={reportId} projectName={currentProjectName}></ViewPdf>

            <Modal
                show={isShow}
                onHide={() => setIsShow('')}
                centered
                className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body >
                    <Modal.Title className='small-logo-wrapper'>
                        <img src={smallLogo} className={`small-logo ${isSmallScreen ? 'mobile' : ''}`}></img>
                        <div>!הסריקה הושלמה</div>
                    </Modal.Title>

                    {
                        isSmallScreen ?
                            <div className='text-right bold'>
                                <div> ,משתמש יקר</div>
                                <div> בכל תוכנת הגהת מחשב ישנו מספר רב </div>
                                <div>, של הערות שווא</div>
                                <div> .אך אנו הצלחנו לצמצמן משמעותית</div>
                                <div> אם נתקלת באחת, ניתן לסננה  </div>
                                <div>.'ב'סינון דו"ח שגיאות </div>
                            </div>
                            :
                            <div className='text-right bold'>
                                <div> ,משתמש יקר</div>
                                <div> ,בכל תוכנת הגהת מחשב ישנו מספר רב של הערות שווא</div>
                                <div> .אך אנו הצלחנו לצמצמן משמעותית</div>
                                <div> .'אם נתקלת באחת, ניתן לסננה ב'סינון דו"ח שגיאות</div>
                            </div>
                    }


                    <div className="dont-show pt-4">
                        {t.uploadScans.dontShowAgain}
                        <input type="checkbox" onChange={(e) => dontShowAgainFilterAlert(e)}></input>
                    </div>
                </Modal.Body>
            </Modal>

            {
                disabledCreateReport == true &&
                <Spinner value={spinner}></Spinner>
            }

        </div >
    )
}
export default ReportPage;
