import React from 'react';
import { getOperatingSystem } from './constants';

const OSInfo = () => {

    return (
        <div>
            <p>Operating System: {navigator.platform}</p>
        </div>
    );
};

export default OSInfo;
