import * as ReportApiClient from '../api/report-api-client'
import { CREATE_REPORT, FETCH_REPORTS, REPORT_SORT_BY_TYPE, FETCH_REPORT, UPDATE_REPORT, FETCH_REPORT_BY_PROJECT_ID, SET_CONFIRMED_SCANS_IDS, SET_INDEXES_TO_UPDATE, SET_IMAGE_ID, SET_COORDS, SET_IMAGE_URL, SET_SORTED_REPORT, PDF } from './types';

export const setPdf = (pdfUrl) => async dispatch => {
    try {
        dispatch({
            type: PDF,
            payload: pdfUrl
        })
    } catch (err) {
        console.log(err);
    }
}

export const setSortReportOcroutput = (report) => async dispatch => {
    console.log('🌉🌉🌉',report);
    try {
        dispatch({
            type: SET_SORTED_REPORT,
            payload: report
        })
    } catch (err) {
        console.log(err);
    }
}

export const fetchReports = (userId) => async dispatch => {
    try {
        const res = await ReportApiClient.reportsFetch(userId)
        dispatch({
            type: FETCH_REPORTS,
            payload: res.data.data
        })
        return res.data.data;
    } catch (err) {
        console.log(err);
    }
}

export const fetchReport = (reportId) => async dispatch => {
    try {
        const res = await ReportApiClient.reportFetch(reportId)
        dispatch({
            type: CREATE_REPORT,
            payload: res.data != null ? res.data[0] : null
        })
        return res.data;
    }
    catch (err) {
        console.log(err);
    }
}

export const fetchReportByProjectId = (obj) => async dispatch => {
    try {
        const res = await ReportApiClient.fetchReportByProjectId(obj)
        console.log('res in fetchReportByProjectId',res);
        
        dispatch({
            type: FETCH_REPORT_BY_PROJECT_ID,
            payload: res.data
        })
        // console.log('res in fetchReportByProjectId', res);
        return res.data;
    }
    catch (err) {
        console.log(err);
    }
}

export const createReport = (scansObjects) => async dispatch => {
    try {
        const res = await ReportApiClient.createReport(scansObjects)
        // console.log('res in createReport', res);
        await dispatch({
            type: CREATE_REPORT,
            payload: res.data.report
        })
        return res;
    }
    catch (error) {
        console.log('error in createReport', error);
        return null;
    }
}

export const createReportSortByType = (report) => dispatch => {
    console.log('createReportSortByType🫷🫷🫷🫷🫷',report);
    
    try {
        //create sorted list by error types,
        //array of objects the key is scanId, the value is the sorted error types
        let reportSortedByType = []
        //here to create the indexesToUpdate full
        let indexesToUpdate = {}
        let imageId = ''
        let width = 0;
        let height = 0;
        const scansIdsToReport = JSON.parse(sessionStorage.getItem('scansIdsToReport'));       
        // report.ocrOutput = report?.ocrOutput?.filter((scan) => scansIdsToReport.includes(scan.scanId));

        report.ocrOutput && report?.ocrOutput.map((scan) => {
            reportSortedByType[scan.scanId] = {
                TOUCHING_LETTER_SELF: [],
                TOUCHING_LETTER_H: [],
                TOUCHING_LETTER_V: [],
                MISSING_LETTER_OR_TOUCHING_LETTER_H: [],
                MISSING_SPACE_BETWEEN_WORDS: [],
                EXTRA_SPACE_BETWEEN_WORDS: [],
                LETTER_SHAPE_CHANGED: [],
                BROKEN_LETTER: [],
                MISSING_WORD: [],
                ADDED_WORD: [],
                ADDED_LETTER: [],
                TOUCHING_LETTERS_READ_AS_NEW: [],
                TOUCHING_LETTERS_READ_AS_ERROR: [],
                STAIN: [],
                EXTRA_SPACE_BETWEEN_LETTERS: [],
            }

            imageId = report.images.find((img) => img.scanId == scan.scanId)._id;
            indexesToUpdate[scan.scanId] = [];
            width = scan.width;
            height = scan.height;

            scan.lines.map((line, i) => {
                line.words.map((word, j) => {
                    word.letters.map((letter, k) => {
                        if (letter.error_type && word.error_type !== 'SWITCHED_WORD' && word.error_type != 'MISSING_WORD' && word.error_type != 'ADDED_WORD')
                            if (!(k > 0 && ((letter.error_type == 'TOUCHING_LETTER_H' && word.letters[k - 1].error_type == 'TOUCHING_LETTER_H'))))
                                if (
                                    // (letter.error_type == 'TOUCHING_LETTER_H' && letter.touching_letter_continuation === 'false') ||
                                    (letter.error_type == 'TOUCHING_LETTER_V' && letter.touching_letter_continuation === 'true') ||
                                    // (letter.error_type != 'TOUCHING_LETTER_H') ||
                                    (letter.error_type != 'TOUCHING_LETTER_V')) {
                                    if (!reportSortedByType[scan.scanId][letter.error_type])
                                        reportSortedByType[scan.scanId][letter.error_type] = []

                                    reportSortedByType[scan.scanId][letter.error_type].push({ "value": letter, "indexesInOriginalArray": [i, j, k], 'imageId': imageId, 'coords': letter.coords, "width": width, "height": height })
                                    indexesToUpdate[scan.scanId].push([i, j, k])
                                }

                    })

                    if (word.error_type && word.error_type !== 'ALTERED_WORD') {
                        if (!reportSortedByType[scan.scanId][word.error_type])
                            reportSortedByType[scan.scanId][word.error_type] = []

                        reportSortedByType[scan.scanId][word.error_type].push({ "value": word, "indexesInOriginalArray": [i, j], 'imageId': imageId, "coords": word.coords, "width": width, "height": height })
                        indexesToUpdate[scan.scanId].push([i, j])
                    }
                })
            })

        })

        if (reportSortedByType) {
            Object.keys(reportSortedByType).forEach((scanId) => {
                const errorTypes = Object.keys(reportSortedByType[scanId]);

                errorTypes.forEach((errorType) => {
                    if (reportSortedByType[scanId][errorType].length === 0) {
                        delete reportSortedByType[scanId][errorType];
                    }
                });
            });
        } else {
            console.log("null value");
        }


        dispatch({
            type: REPORT_SORT_BY_TYPE,
            payload: reportSortedByType
        })

        return indexesToUpdate;
    }
    catch (error) {
        console.log('error in createReportSortByType', error);
    }
}


export const updateReport = (object) => async dispatch => {
    console.log('updateReport♥️♥️♥️♥️', object);
    try {
        const res = await ReportApiClient.update_report(object)
        dispatch({
            type: UPDATE_REPORT,
            payload: res.data
        })
        return res.data.pdf;
    }
    catch (error) {
        console.log(error);
    }
}

export const updateReportPdf = (object) => async dispatch => {
    console.log('currentUser?.isShowImageInNoErrorScan', object);
    
    try {
        const res = await ReportApiClient.updateReportPdf(object)
        dispatch({
            type: UPDATE_REPORT,
            payload: res.data
        })
        return res.data.pdf;
    }
    catch (error) {
        console.log(error);
    }
}

export const setConfirmedScansIds = (scansIds) => async dispatch => {
    try {
        dispatch({
            type: SET_CONFIRMED_SCANS_IDS,
            payload: scansIds
        })
    }
    catch (error) {
        console.log(error);
    }
}

export const setIndexesToUpdate = (obj) => async dispatch => {
    try {
        dispatch({
            type: SET_INDEXES_TO_UPDATE,
            payload: obj
        })
    }
    catch (error) {
        console.log(error);
    }
}

export const setImageId = (imageId) => async dispatch => {
    try {
        dispatch({
            type: SET_IMAGE_ID,
            payload: imageId
        })
    }
    catch (error) {
        console.log(error);
    }
}

export const setImageUrl = (imageUrl) => async dispatch => {
    try {
        dispatch({
            type: SET_IMAGE_URL,
            payload: imageUrl
        })
    }
    catch (error) {
        console.log(error);
    }
}

export const setCoords = (coords) => async dispatch => {
    try {
        dispatch({
            type: SET_COORDS,
            payload: coords
        })
    }
    catch (error) {
        console.log(error);
    }
}