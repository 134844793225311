import { useEffect, useRef, useState } from "react";
import './data-management.scss'
import Modal from 'react-bootstrap/Modal';
import { get_current_user_by_id } from "../../user/api/user-api-client";
import editUser from '../../../utils/assets/edit-user.svg'
import TableRows from "../utils/table-row/TableRows";
import EditUser from "../edit-user/edit-user";
import xModal from '../../../utils/assets/modal-x.svg'
import { exportToExcel } from "../utils/excel-export/excel-export";
import { updateUser } from "../../user/store/action";
import { BASE_URL, formatDate, useLanguage } from "../../../utils/constants";
import axios from "axios";

export default function DataManagement({ dataType, dataList, header, headerList, isEditUser }) {
    const [isShowModal, setIsShowModal] = useState('')
    const [openExportModal, setOpenExportModal] = useState(false)
    const [isShowSideModal, setIsShowSideModal] = useState('')
    const [user, setUser] = useState({})
    const storageNameToSearch = sessionStorage.getItem('storageNameToSearch') || ''
    const [nameToSearch, setNameToSearch] = useState(storageNameToSearch)
    const [DataListToShow, setDataListToShow] = useState([])
    const [FilteredDataListToShow, setFilteredDataListToShow] = useState([])
    const [activeMonth, setActiveMonth] = useState({})
    const [activeYear, setActiveYear] = useState('')
    const [yearList, setYearList] = useState([])
    const [numberOfAllUserScans, setNumberOfAllUserScans] = useState(0)
    const isFirstTime = useRef(true)
    const filterAndSortObject = useRef(null)
    const t = useLanguage();
    useEffect(() => {
        if (dataList && dataList.length > 0 && isFirstTime.current) {
            isFirstTime.current = false
            const sortedDataList = [...dataList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setDataListToShow(sortedDataList)
            setFilteredDataListToShow(sortedDataList)
        }
    }, [dataList])

    const getUser = async (userId) => {
        setIsShowModal(userId)
        setNumberOfAllUserScans(0)
        const userData = await get_current_user_by_id({ 'userId': userId });
        setUser(userData.data)
        const object = { userId: userData.data._id }
        const response = await axios.post(`${BASE_URL}/api/reportlogs/get_scans_number_of_user/`, object);
        console.log(response);
        setNumberOfAllUserScans(response.data.numberOfAllUserScans)
    }

    const updateUserInDataList = (updatedUser) => {
        let filterAndSortObject1 = filterAndSortObject.current;

        const updatedList = DataListToShow.map((item) =>
            item._id === updatedUser._id ? updatedUser : item
        );
        if (filterAndSortObject1 != null) {
            filterAndSortObject1.index -= 1;
            filterAndSort(filterAndSortObject1, updatedList)
        }
        else {
            setDataListToShow(updatedList);
            setFilteredDataListToShow(updatedList);
        }
    };

    const deleteUserInDataList = (updatedUser) => {
        let filterAndSortObject1 = filterAndSortObject.current;
        const updatedList = DataListToShow.filter((item) => item._id !== updatedUser._id);
        if (filterAndSortObject1 != null) {
            filterAndSortObject1.index -= 1;
            filterAndSort(filterAndSortObject1, updatedList)
        }
        else {
            setDataListToShow(updatedList);
            setFilteredDataListToShow(updatedList);
        }
    };

    const filterByUserName = async (value) => {
        setNameToSearch(value)
        sessionStorage.setItem('storageNameToSearch', value)
        const filteredList = [...dataList].filter(data => data?.userName?.toLowerCase()?.includes(value?.toLowerCase()) || data?.customerName?.toLowerCase()?.includes(value?.toLowerCase()));
        setDataListToShow(filteredList);
        setFilteredDataListToShow(filteredList);
    }

    const filterAndSort = async (item, list) => {
        filterAndSortObject.current = item;
        let updatedDataList;
        let sortedItems;
        const type = item.type;
        const subType = item.subOptions[item.index];
        item.index = item.index < item.subOptions.length ? item.index + 1 : 0;
        if (subType) {
            if (item.action == 'sort') {//פונקציות מיון
                if (type == 'createdAt') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const dateA = new Date(a.createdAt);
                        const dateB = new Date(b.createdAt);
                        return subType === 'order' ? dateA - dateB : dateB - dateA;
                    });
                }
                else if (type === 'userName') {
                    const isAscending = subType === 'order';
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const nameA = a.userName || '';
                        const nameB = b.userName || '';
                        return isAscending ? nameA.localeCompare(nameB, 'he') : nameB.localeCompare(nameA, 'he');
                    });
                }
                else if (type === 'customerName') {
                    const isAscending = subType === 'order';
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const nameA = a.customerName || '';
                        const nameB = b.customerName || '';
                        return isAscending ? nameA.localeCompare(nameB, 'he') : nameB.localeCompare(nameA, 'he');
                    });
                }
                else if (type == 'errorsNum') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const maxErrorsA = Math.max(...a.scansList.map(scan => scan.numOfErrors));
                        const maxErrorsB = Math.max(...b.scansList.map(scan => scan.numOfErrors));
                        return maxErrorsB - maxErrorsA;
                    });
                }
                else if (type == 'balance' || type == 'sum' || type == 'sumBeforeCupon') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const maxErrorsA = a[type];
                        const maxErrorsB = b[type];
                        return subType == 'order' ? maxErrorsA - maxErrorsB : maxErrorsB - maxErrorsA;
                    });
                }

            }
            else {//פונציות סינון
                console.log('subType', subType);
                if (type == 'statusCode' || type == 'Role' || type == 'cuponCodeName') {
                    if (subType == 'all')
                        sortedItems = list || dataList;
                    else
                        if (type == 'statusCode')
                            sortedItems = [...list || dataList].filter(data => data.scansList.some(scan => scan[type] == subType));
                        else
                            sortedItems = [...list || dataList].filter(data => data[type] == subType);
                }
                else if (type == 'pdfLink' || type == 'phone') {
                    if (subType == 'all')
                        sortedItems = list || dataList
                    else
                        sortedItems = [...list || dataList].filter(data => data[type] && data[type] !== '');
                }
            }
        }
        if (nameToSearch)
            sortedItems = [...sortedItems || []].filter(data => data?.userName?.toLowerCase()?.includes(nameToSearch?.toLowerCase()) || data?.customerName?.toLowerCase()?.includes(nameToSearch?.toLowerCase()));

        updatedDataList = sortedItems;
        if (updatedDataList) {
            setDataListToShow(updatedDataList);
            setFilteredDataListToShow(updatedDataList);
        }

    }

    const handleExport = () => {
        console.log('FilteredDataListToShow', FilteredDataListToShow);
        // let year = activeYear
        // let month = activeMonth
        // const monthListNumbers = { 1: 'ינואר', 2: 'פברואר', 3: 'מרץ', 4: 'אפריל', 5: 'מאי', 6: 'יוני', 7: 'יולי', 8: 'אוגוסט', 9: 'ספטמבר', 10: 'אוקטובר', 11: 'נובמבר', 12: 'דצמבר ' };

        let filterByDate = [...FilteredDataListToShow].filter((item) => {
            let date = new Date(item.createdAt);
            return (
                date.getFullYear() === activeYear &&
                date.getMonth() + 1 === activeMonth.index
            );
        })
            .map((item) => ({
                [t.admin.userName]: item.userName,
                [t.admin.status]: item.status == '000' ? 'הצליח' : 'נכשל',
                [t.admin.paymentOption]: item.paymentOption,
                [t.admin.createdAt]: item.createdAt,
                [t.admin.amount]: item.amount,
            }))


        console.log('filterByDate', filterByDate);
        if (filterByDate.length > 0) {
            if (dataType == 'payment') {
                exportToExcel(filterByDate, `תשלומים ${activeMonth.name}`);
            }
            else {
                exportToExcel(filterByDate, `שימוש בקופון ${activeMonth.name}`);
            }
        }
        else {
            alert('אין נתונים בטווח הזמן')
        }
    };
    const monthList = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר']

    useEffect(() => {
        const date = new Date()
        const year = date.getFullYear()
        const list = [year, year - 1, year - 2];
        setYearList(list)
        setActiveYear(year)
    }, [])

    return (
        <div className="data-header-container ">
            <div>
                {header && <div className="header-row">
                    <div className="data-header-wrapper">
                        <div className="data-manage-header">{FilteredDataListToShow && FilteredDataListToShow?.length} מתוך {header}</div>
                    </div>
                    <div className="row1">
                        {['cupon', 'payment'].includes(dataType) && <button onClick={setOpenExportModal} className="export-button">יצוא לקובץ אקסל</button>}
                        <input type="text" className='search-input' value={nameToSearch} onChange={(e) => { filterByUserName(e.target.value) }} placeholder="חיפוש"></input>
                    </div>
                </div>}
                <div className={`table-row padd`}>
                    {/* <div className="table-col chooseLine header-bold-text "><input type="checkbox"></input></div> */}
                    {headerList.map(item =>
                        <div type={item.action ? 'button' : ''} className="table-col header-bold-text col" onClick={() => item.action ? filterAndSort(item) : undefined}>{item.label}</div>
                    )}
                    {dataType == 'user' && isEditUser && <div className="table-col edit-button header-bold-text "></div>}
                </div>
                <TableRows dataType={dataType} items={FilteredDataListToShow} getUser={getUser} setIsShowModal={setIsShowModal} setIsShowSideModal={setIsShowSideModal} editUser={editUser} isEditUser={isEditUser}></TableRows>

            </div>

            {/* user data modal */}
            <Modal
                show={isShowModal}
                onHide={() => setIsShowModal('')}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='user-details-modal' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setIsShowModal('')}></img>
                        <div className="bold-text">{'שם משתמש'}</div>
                    </div>

                    <div className="input-style big-input">{user.customerName}</div>
                    <div className="input-style big-input">{user.phone || 'לא קיים טלפון'}</div>
                    <div className="input-style big-input">{user.email}</div>
                    <div className="input-style big-input">{formatDate(user.createdAt)}</div>
                    <div className="input-style big-input">{user.isAddBrokenLetter == true ? 'אות קטועה מופיעה' : 'אות קטועה לא מופיעה'}</div>
                    <div className="row-with-gap">
                        <div className="input-style small-input">יתרה: {user.balance}</div>
                        <div className="input-style small-input">שימוש אחרון: {user.lastPayment}</div>
                    </div>
                    <div className="input-style big-input">סך הסריקות של משתמש זה: {numberOfAllUserScans}</div>
                </Modal.Body>
            </Modal>

            {/* export data modal */}
            <Modal
                show={openExportModal}
                onHide={() => setOpenExportModal(false)}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='user-details-modal' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setOpenExportModal(false)}></img>
                        <div className="bold-text">{'יצוא לאקסל'}</div>
                    </div>

                    <div>אנא בחר את החודש שתרצה לייצא לאקסל</div>

                    <select id="year-select" className="year-select" onChange={(e) => setActiveYear(e.target.value)}>
                        {yearList.map((year) => {
                            return (
                                <option>{year}</option>
                            )
                        })
                        }
                    </select>

                    <div className="month-wrapper">
                        {monthList.map((month, index) => {
                            return (
                                <button className={`month ${activeMonth.name == month ? 'active' : ''}`} onClick={() => setActiveMonth({ name: month, index: index + 1 })}>{month}</button>
                            )
                        })}
                    </div>

                    <div>
                        <button type="button" className="white-button big" onClick={() => setOpenExportModal(false)} >ביטול</button>
                        <button type="button" className={`blue-button big`} onClick={() => handleExport()}>אישור</button>
                    </div>


                </Modal.Body>
            </Modal>

            {/* side modal edit */}
            <Modal
                show={isShowSideModal}
                onHide={() => setIsShowSideModal('')}
                centered
                className={`modal side-modal`}
            >
                <Modal.Body className='edit-user-modal-body' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setIsShowSideModal('')}></img>
                        <div className="bold-text">{'עריכת משתמש'}</div>
                    </div>
                    <EditUser
                        user={isShowSideModal}
                        closeModal={() => setIsShowSideModal('')}
                        updateUserInDataList={updateUserInDataList}
                        deleteUserInDataList={deleteUserInDataList}
                    ></EditUser>
                </Modal.Body>
            </Modal>

        </div>
    )
}