import { useState } from 'react';
import './admin-edit.scss'
import Header from '../../header/header';
import { BASE_URL, smallScreen } from '../../../utils/constants';
import axios from 'axios';

export default function AdminEdit() {
    const isSmallScreen = smallScreen();
    const [openTimeModal, setOpenTimeModal] = useState(false)
    const [cuponCodeFormData, setCuponCodeFormData] = useState({
        percentage: '',
        cuponCodeName: '',
        validity: ''
    });

    const [sponserFormData, setSponserFormData] = useState({
        sponserName: '',
        validity: '',
        sponserLink: '',
        image: '',
        imageData: '',
    });

    const CuponCodeHandleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        setCuponCodeFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        console.log(cuponCodeFormData);

    };

    const sponserHandleChange = (e) => {
        const { name, value } = e.target;
        setSponserFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isCuponCodeFormValid = () => {
        const { percentage, cuponCodeName, validity } = cuponCodeFormData;
        return (
            percentage &&
            !isNaN(percentage) &&
            Number(percentage) > 0 &&
            Number(percentage) <= 100 &&
            cuponCodeName.trim() !== '' &&
            validity.trim() !== ''
        );
    };

    const isSponserFormValid = () => {
        const { sponserName, validity, imageData } = sponserFormData;

        return (
            imageData &&
            sponserName.trim() !== '' &&
            validity.trim() !== ''
        );
    };
    console.log('isSponserFormValid', isSponserFormValid());


    const onSubmit = async () => {
        try {
            if (isCuponCodeFormValid()) {
                console.log(cuponCodeFormData);
                const response = await axios.post(`${BASE_URL}/api/cuponCode/add/`, cuponCodeFormData);
                if (response) {
                    alert('קוד הקופון נוצר בהצלחה')
                    setCuponCodeFormData({
                        percentage: '',
                        cuponCodeName: '',
                        validity: ''
                    })
                }
                else {
                    alert('קוד הקופון לא נוצר')
                }
            }
            if (isSponserFormValid()) {
                console.log(sponserFormData);
                const response = await axios.post(`${BASE_URL}/api/sponser/add/`, sponserFormData);
                if (response) {
                    alert('ספונסר נוסף בהצלחה')
                    setSponserFormData({
                        sponserName: '',
                        validity: '',
                        sponserLink:'',
                        image: ''
                    })
                }
                else {
                    alert('ספונסר לא נוסף')
                }
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Update the state with the file
            setSponserFormData((prevData) => ({
                ...prevData,
                image: file,
            }));

            // Generate a preview URL
            const reader = new FileReader();
            reader.onload = (event) => {
                setSponserFormData((prevData) => ({
                    ...prevData,
                    imageData: event.target.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (

        <div className={`admin-contaier ${isSmallScreen ? 'mobile' : ''}`}>

            <Header page={'admin-edit'} context={['עריכה']}></Header>


            <div className={`admin-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                <div className={`admin-edit-data ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className='cupon-code-header'>קוד קופון </div>
                    <form className='admin-edit-from-wrapper'>
                        <input
                            className="admin-edit-input"
                            type="number"
                            name={'percentage'}
                            value={cuponCodeFormData.percentage}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value.length <= 3) {
                                    CuponCodeHandleChange(e);
                                }
                            }}
                            placeholder={'אחוזי הנחה בקופון'}
                        />
                        <input
                            className="admin-edit-input"
                            type="text"
                            name={'cuponCodeName'}
                            value={cuponCodeFormData.cuponCodeName}
                            onChange={CuponCodeHandleChange}
                            placeholder={'שם הקופון'}
                        />
                        <input
                            className="admin-edit-input"
                            type="date"
                            name={'validity'}
                            value={cuponCodeFormData.validity}
                            onChange={CuponCodeHandleChange}
                            placeholder={'עד תאריך'}
                        />
                    </form>
                </div>

                <div className={`admin-edit-data ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className='cupon-code-header'>נותן חסות </div>
                    <form className='admin-edit-from-wrapper'>
                        <input
                            className="admin-edit-input"
                            type="text"
                            name={'sponserName'}
                            value={sponserFormData.sponserName}
                            onChange={sponserHandleChange}
                            placeholder={'שם החסות'}
                        />
                        <input
                            className="admin-edit-input"
                            type="text"
                            name={'sponserLink'}
                            value={sponserFormData.sponserLink}
                            onChange={sponserHandleChange}
                            placeholder={'לינק של בעל החסות'}
                        />
                        <input
                            className="admin-edit-input"
                            type="date"
                            name={'validity'}
                            value={sponserFormData.validity}
                            onChange={sponserHandleChange}
                            placeholder={'משך זמן החסות'}
                        />

                        {sponserFormData.imageData ? (
                            <img
                                src={sponserFormData.imageData}
                                alt="Sponser Preview"
                                className="image-preview"
                            />
                        )
                            :
                            <div className='file-upload-wrapper'>
                                <div className={'file-upload-container'}>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        accept=".png, .jpg, .svg"
                                        className={'file-upload-input'}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="file-upload" className={'file-upload-label'} >
                                        העלאת לוגו png / svg
                                    </label>
                                </div>
                            </div>
                        }


                    </form>
                </div>
            </div>

            <div className='admin-edit-button'>
                <button
                    type="button"
                    className={`blue-button ${isCuponCodeFormValid() || isSponserFormValid() ? '' : 'disabled'} `}
                    onClick={onSubmit}
                    disabled={!isCuponCodeFormValid() && !isSponserFormValid()}
                >
                    שמירה
                </button>
            </div>
        </div>

    )
}