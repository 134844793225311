import { useEffect, useRef, useState } from "react";
import './cupon-management.scss'
import Header from "../../header/header";
import DataManagement from "../data-management/data-management";
import { BASE_URL } from "../../../utils/constants";
import axios from "axios";
import { getUsersById } from "../../user/store/action";
import { useDispatch } from "react-redux";

export default function CuponManagement() {
    const [list, setList] = useState([]);
    const isEffect = useRef('');
    const dispatch = useDispatch();
    const [cuponsListUpdate, setCuponsListUpdate] = useState([])

    useEffect(() => {
        const fetchUsers = async () => {
            isEffect.current = true;
            let usersIdList = []
            list.forEach(item => {
                usersIdList.push({ id: item.userId })
            });
            const usersList = await dispatch(getUsersById(usersIdList))
            let paymentListWithNames = [...list]
            usersList.map((item, index) => paymentListWithNames[index] = {
                ...paymentListWithNames[index],
                userName: item?.customerName
            })
            setCuponsListUpdate(paymentListWithNames)
        }
        if (list && list.length > 0 && isEffect.current == false) { fetchUsers() }
    }, [list])

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(`${BASE_URL}/api/cuponCodeUses/get_all/`);
            console.log('response 🙏🙏🙏🙏🙏', response);
            setList(response.data)
        };
        fetchData();
    }, []);

    const headerListSortAndFilter = [
        { label: 'שם משתמש', type: 'userName', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'שם קופון', type: 'cuponCodeName', action: 'filter', subOptions: ['אות והדר 770', 'שמעון ביתאן', 'all'], index: 0 },
        { label: 'סכום מקורי', type: 'sumBeforeCupon', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'סכום לאחר הנחה', type: 'sum', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'תאריך שימוש', type: 'createdAt', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
    ]




    return (
        <div>
            <Header page={'user-management'} context={['שימושים בקוד קופון']}></Header>
            {cuponsListUpdate.length > 0 &&
                <DataManagement
                    dataType={'cupon'}
                    dataList={cuponsListUpdate}
                    header={`${list.length} שימושים`}
                    headerList={headerListSortAndFilter}
                ></DataManagement>}
        </div>
    )
}