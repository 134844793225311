import { useDispatch, useSelector } from "react-redux"
import { getAllUsers } from "../../user/store/action";
import { useEffect, useRef, useState } from "react";
import './admin-dashboard.scss'
import { getAllPayments } from "../../payments/store/action";
import Header from "../../header/header";
import { fetchAllReportLogs, fetchReportLogsNumbers } from "../../scans/store/action";
import DataManagement from "../data-management/data-management";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [usersList, setUsersList] = useState([])
    const [usersLength, setUsersLength] = useState(0)
    const [scansList, setScansList] = useState([])
    const [scansLength, setScansLength] = useState(0)
    const [allScansLength, setAllScansLength] = useState(0)
    const [goodScansLength, setGoodScansLength] = useState(0)
    const [failedScansLength, setFailedScansLength] = useState(0)
    const [accessFailedScansLength, setAccessFailedScansLength] = useState(0)
    const [moreThen15Errors, setMoreThen15Errors] = useState(0)
    const usersListStore = useSelector((store) => store.userReducer.users)
    const [paymentsList, setPaymentsList] = useState([])
    const paymentsListStore = useSelector((store) => store.paymentReducer?.payments)
    const isEffect = useRef(false);
    const isUsersEffect = useRef(false);
    const getPaymentsList = async () => {
        const paymentsList = await dispatch(getAllPayments())
        const last10payments = paymentsList?.slice(-10);
        setPaymentsList(last10payments)
    }
    useEffect(() => {
        if (isEffect.current == false) {
            isEffect.current = true;
            getScansList()
        }
    }, [])

    useEffect(() => {
        getPaymentsList()
    }, [paymentsList])

    useEffect(() => {
        setPaymentsList(paymentsListStore)
    }, [paymentsListStore])


    const getUsersList = async () => {
        const usersList = await dispatch(getAllUsers())
        setUsersLength(usersList.length)
        const last10users = usersList?.slice(-8);
        setUsersList(last10users)
    }

    const getScansList = async () => {
        const reportLogsData = await dispatch(fetchReportLogsNumbers())
        console.log('reportLogsData', reportLogsData);
        
        setScansLength(reportLogsData.listLength)
        setScansList(reportLogsData.last10scans)
        setAllScansLength(reportLogsData.scanListLength)
        setGoodScansLength(reportLogsData.status200)
        setFailedScansLength(reportLogsData.status400)
        setAccessFailedScansLength(reportLogsData.status500)
        setMoreThen15Errors(reportLogsData.moreThen15Errors)
    }

    useEffect(() => {
        if (isUsersEffect.current == false) {
            isUsersEffect.current = true;
            getUsersList()
        }
    }, [])

    useEffect(() => {
        setUsersList(usersListStore)
    }, [usersListStore])

    const dashboardHeadersList = [
        { title: 'סך הקריאות', value: scansLength, color: 'gold' },
        { title: 'סך המשתמשים ', value: usersLength, color: 'silver' },
        { title: 'סך הסריקות', value: allScansLength, color: 'blue' },
        { title: 'סריקות שהצליחו', value: goodScansLength, color: 'green' },
        { title: 'סריקות שנכשלו', value: failedScansLength, color: 'orange' },
        { title: 'הגישה נכשלה', value: accessFailedScansLength, color: 'yellow' },
        { title: 'מעל 15 שגיאות', value: moreThen15Errors, color: 'red' },
    ]

    const lastDataList = [
        { dataType: 'scan', list: scansList, title: 'סריקות אחרונות', link: '/scan-management', headerList: [{ label: 'שם משתמש' }, { label: 'מס שגיאות' }, { label: 'לינק לסריקה' }, { label: 'לינק לדוח' }, { label: 'דוח מלא' }, { label: 'תאריך הסריקה' }, { label: 'סטטוס' }] },
        { dataType: 'user', list: usersList, title: 'משתמשים אחרונים', link: '/user-management', headerList: [{ label: 'שם משתמש' }, { label: 'סוג משתמש' }, { label: 'אימייל' }, { label: 'טלפון' }, { label: 'תאריך הצטרפות' }, { label: 'יתרה' }] },
        { dataType: 'payment', list: paymentsList, title: 'תשלומים אחרונים', link: '/payment-management', headerList: [{ label: 'שם משתמש' }, { label: 'דרך תשלום' }, { label: 'תאריך רכישה' }, { label: 'סכום' }, { label: 'סטטוס' }] },
    ]

    return (
        <div className="admin-dashboard-wrapper">
            <Header page={'admin-dashboard'} context={['דאשבורד']}></Header>
            <div className="dashboard-headers-list">
                {dashboardHeadersList.map((obj) => {
                    return (
                        <div className={`dashboard-headers-data col ${obj.color}`}>
                            <div className="title">{obj.title}</div>
                            <div className="big-number">{obj.value}</div>
                        </div>
                    )
                })}
            </div>

            <div className="last-data-container">
                {lastDataList.map((item) => {
                    return (
                        <div className="last-data-wrapper">
                            <div className="row1">
                                <div>{item.title}</div>
                                <div type='button' onClick={() => navigate(item.link)} className="list-link">{`לרשימה המלאה >`}</div>
                            </div>
                            <DataManagement
                                dataType={item.dataType}
                                dataList={item.list}
                                headerList={item.headerList}
                            ></DataManagement>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}