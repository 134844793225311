import { useNavigate } from 'react-router-dom';
import './about-style.scss'
import OSInfo from '../../utils/os-info';
import SendEmailForAllUsers from '../../utils/send-email/send-email-for-all-users';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { BASE_URL } from '../../utils/constants';
// import Median from "median-js-bridge";


export default function About() {
    const navigate = useNavigate();
    const [url, setUrl] = useState('')
    const [isNativeApp, setisNativeApp] = useState('')
    const [getPlatform, setgetPlatform] = useState('')
    const pay = () => {
        sessionStorage.setItem('sum', JSON.stringify(50));
        navigate('/payment', { state: { sum: 50, currency: 'ILS' } })
    }
    // useEffect(() => {
    //     Median.onReady(() => {
    //         window.alert("Median app ready!");
    //         setisNativeApp(Median.isNativeApp())
    //         setgetPlatform(Median.getPlatform())
    //     });
    // }, []);
    // Median.getPlatform().then((platform) => {
    //     setgetPlatform(platform); // Output: 'web'
    // });

    // console.log('isNativeApp', Median.isNativeApp());
    // console.log('getPlatform', Median.getPlatform());

    const handleChange = (e) => {
        setUrl(e.target.value)
    }
    const download = async () => {
        const encodedLink = encodeURIComponent(url);
        const response = await axios.get(`${BASE_URL}/api/file/downloadImage/${encodedLink}`, { responseType: 'blob' });
        const blob = new Blob([response.data], { type: 'image/png' }); // Change to the appropriate image MIME type (e.g., 'image/jpeg' for .jpg)
        saveAs(blob, `image.png`); // Change the extension to match the image type
    }
    const downloadMany = async () => {
        const manyIds = [
            "66b1ea871bfe24ceaa9f15f0",
            "66bc6d54f0ff699f4790d39e",
            "66bc757af0ff699f4790d5c4",
            "66bc775df0ff699f4790d6fc",
            "66be48783ea2b74150dae0f8",
            "66be49043ea2b74150dae139",
            "66be49c83ea2b74150dae180",
            "66be4aec3ea2b74150dae1d6",
            "66be4b943ea2b74150dae24e",
            "66be4c3a3ea2b74150dae27c",
            "66be4cf13ea2b74150dae2ad",
            "66be4f0c3ea2b74150dae312",
            "66be4f7f3ea2b74150dae349",
            "66be50183ea2b74150dae383",
            "66be514e3ea2b74150dae3c4",
            "66be52443ea2b74150dae407",
            "66be52d03ea2b74150dae44d",
            "66be53513ea2b74150dae493",
            "66be54193ea2b74150dae4dd",
            "66be54b33ea2b74150dae533",
            "66be55803ea2b74150dae582",
            "66be55d33ea2b74150dae5ed",
            "66be577f3ea2b74150dae67a",
            "66be57df3ea2b74150dae6d6",
            "66be588c3ea2b74150dae732",
            "66be590e3ea2b74150dae790",
            "66be59cd3ea2b74150dae7f2",
            "66be5a6d3ea2b74150dae858",
            "66be5b323ea2b74150dae8de",
            "66be5b94a9741e2a89172121"
        ]
        for (let index = 0; index < manyIds.length; index++) {
            const element = manyIds[index];
            const url = `https://stamimages.blob.core.windows.net/original-images/scan${element}.jpg`

            const encodedLink = encodeURIComponent(url);
            const response = await axios.get(`${BASE_URL}/api/file/downloadImage/${encodedLink}`, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'image/png' }); // Change to the appropriate image MIME type (e.g., 'image/jpeg' for .jpg)
            saveAs(blob, `image.png`); // Change the extension to match the image type
        }
    }

    return (
        <>
            <div className="">
                {/* <input placeholder='הכנס לינק של תמונה' onChange={handleChange}></input> */}
                {/* <button onClick={download}>הורדה</button>
                <button onClick={downloadMany}>הורדה מרובה</button> */}
                {/* <div className="bold-text pt-3">כתובת בית העסק:</div>
                <div>טבריה 33 בני ברק</div>
                <div className="bold-text pt-3">תקנון מדיניות ביטול עסקה:</div>
                <div>"ביטול עסקה בהתאם לתקנות הגנת הצרכן (ביטול עסקה), התשע"א-2010 וחוק הגנת הצרכן, התשמ"א-1981"
                </div>
                <br></br> */}
                isNativeApp:
                {/* <div>{Median.isNativeApp().toString()}</div> */}
                ------------------------------------------------------------
                <br></br>
                getPlatform
                {/* <div>{getPlatform.toString()}</div> */}
                -------------------------------------------------------------
                <br></br>
                <a href='https://median.dev/document-scanner'>document-scanner</a>
                
                -------------------------------------------------------------
                <br></br>

                <OSInfo></OSInfo>
                {/* window.innerWidth: {window.innerWidth} */}
                {/* <div type='button' className='about-pay ' onClick={pay}>למעבר לדף תשלום</div> */}
                {/* {
                    (process.env.REACT_APP_ENVIRONMENT == 'dev' || process.env.REACT_APP_ENVIRONMENT == 'local') &&
                    <SendEmailForAllUsers></SendEmailForAllUsers>
                } */}
            </div>
        </>
    )
}
