import Modal from 'react-bootstrap/Modal';
import { smallScreen, useLanguage } from '../constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Median from "median-js-bridge";

export default function ViewPdf({ isShowPdf, setIsShowPdf, reportId, projectName }) {
    const [downloadClick, setDownloadClick] = useState(false)
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [isOpenIframe, setIsOpenIframe] = useState(false);
    const [isDouwload, setIsDouwload] = useState('');

    const t = useLanguage();
    const isSmallScreen = smallScreen();
    // useEffect(() => {
    //     Median.onReady(() => {
    //         console.log("Median app ready!");
    //     });
    // }, []);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    const downloadPDFfromURL = async (pdfUrl, fileName = `${projectName}.pdf`) => {
        console.log('downloadPDFfromURL');
        try {
            setDownloadClick(true)
            // const platform = navigator.platform.toLowerCase();

            // if (isSmallScreen && !platform.includes('iphone')) {
            setIsDouwload('')
            const newWindow = window.open(pdfUrl, '_blank')

            if (newWindow) {
                setTimeout(() => {
                    if (newWindow.closed) {
                        setIsDouwload(isShowPdf)
                    }
                }, 2000);
            } else {
                console.error('Failed to open the window.');
            }

            // }
            // else {
            //     const newWindow = window.open(pdfUrl, '_blank')

            // }
            // else {
            //     const response = await fetch(pdfUrl);
            //     console.log(response);
            //     const blob = await response.blob();
            //     const url = window.URL.createObjectURL(blob);
            //     const a = document.createElement('a');
            //     a.href = url;
            //     a.download = fileName;
            //     document.body.appendChild(a);
            //     a.click();
            //     document.body.removeChild(a);
            //     window.URL.revokeObjectURL(url);
            // }

        }
        catch (error) {
            console.error('Error downloading PDF:', error);
        }
        finally {
            setDownloadClick(false)
        }
    };


    return (
        <div>
            <Modal
                show={isShowPdf}
                onHide={() => setIsShowPdf('')}
                centered
                className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body className='pdf-modal' >
                    <Modal.Title>{t.report.pdfReady}</Modal.Title>
                    <div className={`pdf-link-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        <button className={`open-pdf-link ${downloadClick ? 'disabled' : ''}`} onClick={downloadClick ? undefined : () => { downloadPDFfromURL(isShowPdf); }} >{isSmallScreen ? downloadClick == true ? t.report.downloadClicked : t.report.download : t.report.open}</button>
                    </div>
                </Modal.Body>
            </Modal>

            {isDouwload && <iframe src={isShowPdf} width="1px" height="1px">
            </iframe>}

        </div>
    )
}
